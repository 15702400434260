<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="nav-tab-heading">
          Favoriten
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div
      class="px-1 py-5 text-center"
      style="font-size: 14px"
      v-if="favorites.length === 0"
    >
      Sie haben bisher noch<br />
      keine Favoriten markiert
    </div>

    <component
      v-for="favorite in favorites"
      v-bind="favorite.props"
      :key="favorite.id"
      :is="favorite.component"
      @edit="$emit('edit-group', $event)"
    ></component>
  </v-list>
</template>

<script>
export default {
  name: "NavigationFavoritesList",
  components: {
    NavigationGroupListItem: () => import("./NavigationGroupListItem"),
    NavigationUserListItem: () => import("./NavigationUserListItem"),
  },
  computed: {
    favorites() {
      const users = this.$store.getters["users/favorites"].map((user) => ({
        ...user,
        component: "NavigationUserListItem",
        props: { deleteButton: false, user },
      }));
      const groups = this.$store.getters["groups/favorites"].map((group) => ({
        ...group,
        component: "NavigationGroupListItem",
        props: { group },
      }));
      return users.concat(groups);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.nav-tab-heading {
  color: $color-primary;
  margin: 14px 0;
}

.theme--dark {
  &.v-list-item {
    padding: 0 6px;
  }
}
</style>
